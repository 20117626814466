/**
 * Auth0 permissions.
 * This will be synced to the Auth0 instance.
 */
const Auth0Permission = {
  /**
   * TODO: Update this later.
   * Access to Archus NextJS Template.
   */
  APPLICATION_ACCESS: "archus-cloud-contact-center:access",
  CALL: {
    CREATE: "call:create",
    DELETE: "call:delete",
    READ: "call:read",
    UPDATE: "call:update",
  },
  USER: {
    CREATE: "user:create",
    DELETE: "user:delete",
    READ: "user:read",
    UPDATE: "user:update",
  },
};

export default Auth0Permission;
