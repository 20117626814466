"use client";

/**
 * Third-party libraries.
 */
import { PropsWithChildren } from "react";

/**
 * Project components.
 */
import {
  DefaultProtectedPageLayout,
  DefaultProtectedPageLayoutHeader,
  DefaultProtectedPageLayoutSidebar,
} from "@/components/client/layout";

/**
 * Application's default protected layout properties.
 */
type ApplicationDefaultProtectedPageLayoutProps = PropsWithChildren<{}>;

/**
 * Application's default protected page layout.
 *
 * Wrap your page with this when you want the default project header, sidebar and authentication
 * for the page.
 */
export function ApplicationDefaultProtectedPageLayout({
  children,
}: ApplicationDefaultProtectedPageLayoutProps) {
  return (
    <DefaultProtectedPageLayout
      header={<DefaultProtectedPageLayoutHeader />}
      sidebar={<DefaultProtectedPageLayoutSidebar />}
    >
      {children}
    </DefaultProtectedPageLayout>
  );
}
