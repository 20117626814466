/**
 * Details about the country and their phone number.
 */
export type CountryDetails = {
  /**
   * Country code for the phone number.
   *
   * @example
   * 65
   */
  code: number;
  /**
   * Emoji of the country flag.
   *
   * @example 🇸🇬
   */
  flag: string;
  /**
   * Name of the country.
   *
   * @example
   * Singapore
   */
  name: string;
  /**
   * Details about the phone number of the country.
   */
  phoneNumber: {
    /**
     * Length of the phone number without the starting number.
     *
     * @example
     * 7
     */
    length: {
      /**
       * Minimum length of the phone number wihtout the starting number.
       *
       * @example
       * 7
       */
      min: number;
      /**
       * Maximum length of the phone number wihtout the starting number.
       *
       * @example
       * 7
       */
      max: number;
    };
    /**
     * List of possible starting numbers for the phone number.
     * This is included on the phone number length.
     *
     * @example
     * [6, 8, 9]
     */
    startsWith?: number[];
  };
};

/**
 * List of countries and their details.
 * Also includes the validation for their corresponding phone number.
 */
export const COUNTRY: Record<
  | "Singapore"
  | "UnitedStates"
  | "Indonesia"
  | "Malaysia"
  | "HongKong"
  | "Australia"
  | "China",
  CountryDetails
> = {
  Singapore: {
    code: 65,
    flag: "🇸🇬",
    name: "Singapore",
    phoneNumber: {
      length: {
        min: 7,
        max: 7,
      },
      startsWith: [6, 8, 9],
    },
  },
  UnitedStates: {
    code: 1,
    flag: "🇺🇸",
    name: "United States",
    phoneNumber: {
      length: {
        min: 10,
        max: 10,
      },
    },
  },
  Indonesia: {
    code: 62,
    flag: "🇮🇩",
    name: "Indonesia",
    phoneNumber: {
      length: {
        min: 10,
        max: 10,
      },
    },
  },
  Malaysia: {
    code: 60,
    flag: "🇲🇾",
    name: "Malaysia",
    phoneNumber: {
      length: {
        min: 9,
        max: 9,
      },
    },
  },
  HongKong: {
    code: 852,
    flag: "🇭🇰",
    name: "Hong Kong",
    phoneNumber: {
      length: {
        min: 8,
        max: 8,
      },
    },
  },
  Australia: {
    code: 61,
    flag: "🇦🇺",
    name: "Australia",
    phoneNumber: {
      length: {
        min: 10,
        max: 10,
      },
    },
  },
  China: {
    code: 86,
    flag: "🇨🇳",
    name: "China",
    phoneNumber: {
      length: {
        min: 11,
        max: 11,
      },
    },
  },
};

/**
 * List of country codes and their details.
 * Also includes the validation for their corresponding phone number.
 */
export const COUNTRY_CODE: Record<
  "65" | "1" | "62" | "60" | "852" | "61" | "86",
  CountryDetails
> = {
  "65": {
    code: 65,
    flag: "🇸🇬",
    name: "Singapore",
    phoneNumber: {
      length: {
        min: 7,
        max: 7,
      },
      startsWith: [6, 8, 9],
    },
  },
  "1": {
    code: 1,
    flag: "🇺🇸",
    name: "United States",
    phoneNumber: {
      length: {
        min: 10,
        max: 10,
      },
    },
  },
  "62": {
    code: 62,
    flag: "🇮🇩",
    name: "Indonesia",
    phoneNumber: {
      length: {
        min: 10,
        max: 10,
      },
    },
  },
  "60": {
    code: 60,
    flag: "🇲🇾",
    name: "Malaysia",
    phoneNumber: {
      length: {
        min: 9,
        max: 9,
      },
    },
  },
  "852": {
    code: 852,
    flag: "🇭🇰",
    name: "Hong Kong",
    phoneNumber: {
      length: {
        min: 8,
        max: 8,
      },
    },
  },
  "61": {
    code: 61,
    flag: "🇦🇺",
    name: "Australia",
    phoneNumber: {
      length: {
        min: 10,
        max: 10,
      },
    },
  },
  "86": {
    code: 86,
    flag: "🇨🇳",
    name: "China",
    phoneNumber: {
      length: {
        min: 11,
        max: 11,
      },
    },
  },
};
